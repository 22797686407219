import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { LightboxService } from '../shared/lightbox.service';
import { UiService } from '../shared/ui.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  fields                  = 1;
  uniqueId: any;

  shareForm: FormGroup;
  shareResults            = false;

  isSubmitting            = false;

  hgc                     = false;

  constructor(
    private fb: FormBuilder,
    private ui: UiService,
    private api : ApiService,
    private router: Router,
    private activeroute: ActivatedRoute,
    private lightbox: LightboxService ) { }

  ngOnInit(): void {

    // this.uniqueId             = parseInt(this.activeroute.snapshot.params['uniqueid']);
    this.uniqueId             = this.activeroute.snapshot.params['uniqueid'];
    if(this.router.url.includes('hgc')) {
      this.hgc                = true;
    }
    
    this.shareForm = this.fb.group({
        data        : this.fb.array([this.createFormGroup()])
    });

    this.lightbox.setReload()
    .subscribe(data => {
      if(data && data.hasOwnProperty('template') && data['template'] && data['template'] == "reloadShare" ) {
        this.removeRecipient(data.data);
      }
    });

  }

  private createFormGroup(): FormGroup {
    return this.fb.group({
      firstName     : ['', [Validators.required]],
      email         : ['', [Validators.required, Validators.email]],
    });
  }

  addRecipient(index) {
    this.fields    = this.fields + 1;
    const emails = this.shareForm.get('data') as FormArray;
    emails.insert(index+1, this.createFormGroup());
  }

  confirmationPopup(index) {
    this.lightbox.showLightbox        = true;
    this.lightbox.lightboxInfo        = {heading: 'Are you sure you want to delete this contact?', id: index};
    this.lightbox.lightboxTemplate    = 'confirmationPopup';
  }

  removeRecipient(index) {
    this.fields    = this.fields - 1;
    const emails = this.shareForm.get('data') as FormArray;
    emails.removeAt(index);
  }

  share() {

    this.isSubmitting       = true;

    const payload           = this.shareForm.value;
    payload.shareResult     = this.shareResults;
    payload.uniqueUrl       = this.uniqueId;

    console.log(payload);
    if(this.hgc) {
      payload['partner']    = "hgc";
    }

    this.api.post('v1/form/submit', payload)
      .subscribe(data => {
        if(data.status == "success") {
          this.isSubmitting       = false;
          this.router.navigate(['confirmed/' + this.uniqueId]);
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again. (Error No: 0001)", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network Error, Please check the network and do it again. (Error No: 0002)", "failure");
      });

  }

  showErrors() {
    this.shareForm.markAllAsTouched();
  }

}
