import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { filter } from 'rxjs/operators';

import { Router, NavigationEnd } from '@angular/router';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';

import * as AOS from 'aos';

@Component({
  selector: 'app-churchos',
  templateUrl: './churchos.component.html',
  styleUrls: ['./churchos.component.scss']
})
export class ChurchosComponent implements OnInit {

  // loading boolean
  isLoading                   = true;
  loadingTxt                  = "Preparing the assessment";

  // display screens
  screens                     = [true, false, false];

  // questions variables
  questions                   = [];
  currentQuestion             = 1;
  totalQuestions              = 21;
  questionError               = false;

  // form variables
  displayForm                 = false;
  form: FormGroup;

  // data variables
  partner: string;

  constructor(
    private api: ApiService,
    private ui: UiService,
    private fb: FormBuilder,
    private router: Router ) {
      AOS.init({
        'once': true
      });
    }

  ngOnInit(): void {

    if(this.router.url.includes('hgc')) {
      this.partner            = "hgc";
    }

    this.form = this.fb.group({
      firstName  : ['', [Validators.required]],
      lastName   : ['', [Validators.required]],
      email      : ['', [Validators.required, Validators.email]],
      churchName : ['', [Validators.required]],
      zipCode    : ['', [Validators.required, Validators.pattern(/^(?:[a-zA-Z0-9\s]+)?$/) ]],
    });

    this.fetchData();
  }

  // fetch data from the api
  fetchData() {
    this.api.get('v1/form/questions')
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          this.questions                  = data.data.map(question => ({...question, answer: 0 }));
          this.isLoading                  = false;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
    });
  }

  // show next screen
  openScreen(index) {
    this.screens[index-1]       = true;
    setTimeout(() => {
      document.querySelector('#screen' + index).scrollIntoView(
        { behavior: 'smooth' }
      );
    }, 1);
  }

  // switch question either by submitting question or next/prev button
  switchQuestion(index, showLater?:boolean, newValue?) {
    this.questionError            = false;
    if(index > 0 && index <= this.totalQuestions) {
      let currentQuestion         = this.questions[this.currentQuestion-1];
      if(currentQuestion.answer === 0 && index > this.currentQuestion ) {
        this.questionError        = true;
        return;
      }
      if(showLater) {
        setTimeout(() => {
          this.currentQuestion        = index;
        }, 500 );
        // this.currentQuestion        = index;
      } else {
        this.currentQuestion          = index;
      }
    }
    if(this.currentQuestion === this.totalQuestions) {
      let currentQuestion             = this.questions[this.currentQuestion-1];
      // console.log(newValue);
      // console.log(currentQuestion);
      if(newValue && currentQuestion.answer != newValue) {
        currentQuestion.answer        = newValue;
      }
      if(currentQuestion.answer === 0) {
        this.questionError            = true;
        return;
      } else {
        this.displayForm              = true;
      }
      
      
    }
  }

  // submit form to the API
  submitForm() {

    this.isLoading            = true;
    this.loadingTxt           = "";

    const payload             = this.form.value;
    let answers               = this.questions.map(question => {
      return {
        questionId: question.id,
        answer: question.answer }
      }
    );
    payload['answers']        = answers;
    if(this.partner) {
      payload['partner']      = this.partner;
    }

    // this.router.navigate(['report/19697']);

    this.api.post('v1/form', payload)
      .subscribe(data => {
        if(data.status == "success") {
          if(this.partner) {
            this.router.navigate([this.partner + '-report/' + data.uniqueId]);
          } else {
            this.router.navigate(['report/' + data.uniqueId]);
          }
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again. (Error No: 0001)", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network Error, Please check the network and do it again. (Error No: 0002)", "failure");
      });

  }

  // hide form & show questions
  hideForm() {
    this.displayForm                  = false;
  }

  // get total number of percentage for mat progress
  getPercentage() {
    return Math.round( ( (this.currentQuestion - 1) /this.totalQuestions ) * 100 );
  }

  // remove colon from the question
  removeColon(data) {
    return data.replace(/:/g,'');
  }

  // accept only numbers
  numberOnly(event): boolean {
    console.log(event);
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
    // const charCode = (event.which) ? event.which : event.keyCode;
    // if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    //   return false;
    // }
    // return true;
  }

  showErrors() {
    this.form.markAllAsTouched();
  }

}

