<!--  ========================= header =========================== -->
<div class="ic-header" *ngIf="showHeaderFooter">
  <div class="frame">

    <!-- logo -->
    <div class="header-logo">
      <img src='./../assets/img/logo-ic-new.png'>
    </div>

    <!-- menu -->
    <!-- <div class="header-menu" [class.menu-opened]="isMenuOpened" *ngIf="showmenu">
      <a target="_blank" href="https://ic.40parables.com/churchos/" >
        ChurchOS
      </a>
      <a href="javascript:void(0)" class="main-item" (click)="menuItemClick(0)" [class.main-item-opened]="showSubMenu[0]">
        Resources
        <span class="material-icons">keyboard_arrow_down</span>
        <div class="main-item-submenu" *ngIf="innerWidth > 767 || showSubMenu[0]">
          <a target="_blank" href="https://ic.40parables.com/blog/" class="submenu-item">Blog</a>
        </div>
      </a>
      <a href="javascript:void(0)" class="main-item" (click)="menuItemClick(1)" [class.main-item-opened]="showSubMenu[1]">
        About Us
        <span class="material-icons">keyboard_arrow_down</span>
        <div class="main-item-submenu" *ngIf="innerWidth > 767 || showSubMenu[1]">
          <a target="_blank" href="https://ic.40parables.com/about/" class="submenu-item">Intentional Churches</a>
          <a target="_blank" href="https://ic.40parables.com/churches-and-coaches/" class="submenu-item">Churches & Coaches</a>
          <a target="_blank" href="https://ic.40parables.com/approach/" class="submenu-item">Approach</a>
        </div>
      </a>
      <a href="javascript:void(0)">Contact</a>
    </div> -->

    <!-- <div class="mobilebutton" (click)="toggleMenu()" [class.menu-opened]="isMenuOpened">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </div> -->

  </div><!-- frame -->
</div><!-- ic-header -->

<!--  ====================== router outlet ======================== -->
<router-outlet (activate)="onActivate($event)" ></router-outlet>

<!--  ====================== footer ========================
<div class="ic-footer" *ngIf="showHeaderFooter">
  <div class="frame">
    <div class="footer-1">
      <a href="https://ic.40parables.com/" target="_blank"><img src="../assets/img/white-logo.svg"></a>
      <div class="footer-1-address">
        840 S. Rancho Dr. #4-414,<br/>
        Las Vegas, NV 89106
      </div>
    </div>
    <div class="footer-2">
      <a href="https://ic.40parables.com/about/" target="_blank">ABOUT</a>
      <a href="https://ic.40parables.com/churchos/" target="_blank">CHURCHOS</a>
      <a href="https://ic.40parables.com/churchos/" target="_blank">CHURCHES & COACHES</a>
    </div>
    <div class="footer-3">
      <a href="https://ic.40parables.com/blog/" target="_blank">BLOG</a>
      <a href="https://ic.40parables.com/books/" target="_blank">BOOKS</a>
      <a href="https://ic.40parables.com/contact/" target="_blank">CONTACT</a>
    </div>
    <div class="footer-4">
      <div class="footer-4-social">
        <a href="https://twitter.com/intentchurches" target="_blank">
          <i class="fa fa-twitter"></i>
        </a>
        <a href="https://www.facebook.com/intentionalchurches" target="_blank">
          <i class="fa fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/intentionalchurches/" target="_blank">
          <i class="fa fa-instagram"></i>
        </a>
      </div>
      <a class="phone" href="tel:8005439770">800-543-9770</a>
      <a class="email" href="mailto:info@intentionalchurches.com">info@intentionalchurches.com</a>
      <div class="copyright">
        &copy; Intentional Churches 2021 | <a href="http://intentionalchurches.com/privacy-policy/" target="_blank">Privacy Policy</a>
      </div>
    </div>
    <!-- <div class="ic-footer-left">
      &copy; Intentional Churches 2021 | <a href="http://intentionalchurches.com/privacy-policy/" target="_blank">Privacy Policy</a>
    </div>
    <div class="ic-footer-right">
      <a href="https://twitter.com/intentchurches" target="_blank">
        <i class="fa fa-twitter"></i>
      </a>
      <a href="https://www.facebook.com/intentionalchurches" target="_blank">
        <i class="fa fa-facebook"></i>
      </a>
      <a href="https://www.instagram.com/intentionalchurches/" target="_blank">
        <i class="fa fa-instagram"></i>
      </a>
    </div> -->
  <!-- </div>
</div> -->

<!--  ====================== lightbox ======================== -->
<app-lightbox *ngIf="lightbox.showLightbox"
  [lightboxTemplate]="lightbox.lightboxTemplate"
  [lightboxInfo]="lightbox.lightboxInfo"
  (onClose)="lightbox.close()">
</app-lightbox>


