<div class="share-page share-confirmation-page">
  <div class="frame-medium">

    <div class="share-wrapper">

      <!-- logo -->
      <div class="share-logo">
        <img src='./../../assets/img/logo-assessment.png'>
      </div>

      <!-- confirmation tick -->
      <div class="confirmation-tick">
        <svg class="tick-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <path class="tick-svg-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
      </div>


      <!-- title -->
      <div class="share-title">
        You’ve successfully shared the<br>
        ChurchOS Assessment with your team!
      </div>

      <!-- confirmation-note -->
      <div class="confirmation-note">
        Your team will be notified via email shortly.
      </div>

      <!-- back to results -->
      <div class="back-to-results">
        <a href="https://intentionalchurches.com/book-resources-download/">Check out other resources</a>
      </div>

    </div><!-- share-wrapper -->

  </div><!-- frame-small -->
</div><!-- share-page -->
