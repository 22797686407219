<div class="report-page" *ngIf="!isLoading">

  <div class="tools">
    <div class="download" id="print_button" (click)="download()">
      <span class="material-icons">system_update_alt</span>
      <a>{{ downloadBtn }}</a>
    </div>
    <div class="print" (click)="print()">
      <span class="material-icons">print</span>
      <a>Print</a>
    </div>
  </div>

  <div class="frame-medium report-page-frame">

    <!-- title -->
    <div class="title" data-aos="fade-up">{{ firstName | titlecase }}, your church's score is</div>

    <!-- bar -->
    <div class="progress-bar" data-aos="fade-up" data-aos-delay="100">
      <div class="progress" [style.margin-left.%]="perScore - 3">
        {{ score | number : '1.0-2' }}
      </div>
      <div class="bars">
        <div class="low"></div>
        <div class="medium"></div>
        <div class="high"></div>
        <div class="bar" [style.left.%]="perScore"></div>
        <div class="low-text">21</div>
        <div class="medium-text">63</div>
        <div class="high-text">126</div>
      </div>
    </div>

    <!-- summary -->
    <div class="report-summary" data-aos="fade-up" data-aos-delay="200">
      <ng-container *ngIf="score < 21">
        <b>It looks like your church is struggling to activate the Great Commission</b><br/>
        and drive Kingdom impact. But don’t worry, you can nurture it back to<br/>
        health with prioritized attention and intentional action.
      </ng-container>
      <ng-container *ngIf="score >= 21 && score < 63">
        <b>Your church is doing well but not functioning at its best.</b><br/>
        There’s room for improvement, which is a good thing as it gives you<br/>
        an opportunity to take intentional steps toward being effective.
      </ng-container>
      <ng-container *ngIf="score >= 63">
        <b>Congratulations! Your church is doing a great job</b><br/>
        at activating the Great Commission and driving Kingdom impact.<br/>
        With the right resources and prioritized focus, you can do even better!
      </ng-container>
    </div>

    <!-- learn from reponse -->
    <div class="report-block">

      <!-- title & tools -->
      <div class="block-title" data-aos="fade-up">
        Evaluate areas for growth in your church
      </div>

      <!-- expansion panel -->
      <div class="report-expansion" data-aos="fade-up">
        <mat-accordion>

          <mat-expansion-panel *ngIf="greenQuestions.length > 0" expanded (opened)="openPanel(0)" (closed)="closePanel(0)" hideToggle [class.downloading]="isDownloading">
            <mat-expansion-panel-header class="green-header">
              <mat-panel-title>
                {{ greenQuestions.length }}
                <ng-container *ngIf="greenQuestions.length === 1; else areas">area</ng-container>
                <ng-template #areas>areas</ng-template> 
                where you’re activating impact
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon *ngIf="!panelOpenState[0]">add</mat-icon>
                <mat-icon *ngIf="panelOpenState[0]">remove</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
              <p *ngFor="let data of greenQuestions" [innerHTML]="data.questionData?.question"></p>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="yellowQuestions.length > 0" (opened)="openPanel(1)" (closed)="closePanel(1)" hideToggle [class.downloading]="isDownloading">
            <mat-expansion-panel-header class="yellow-header">
              <mat-panel-title>
                {{ yellowQuestions.length }}
                <ng-container *ngIf="yellowQuestions.length === 1; else areas">area that needs improvement</ng-container>
                <ng-template #areas>areas that need improvement</ng-template>
                
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon *ngIf="!panelOpenState[1]">add</mat-icon>
                <mat-icon *ngIf="panelOpenState[1]">remove</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p *ngFor="let data of yellowQuestions" [innerHTML]="data.questionData?.question"></p>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="redQuestions.length > 0" (opened)="openPanel(2)" (closed)="closePanel(2)" hideToggle [class.downloading]="isDownloading">
            <mat-expansion-panel-header class="red-header">
              <mat-panel-title>
                {{ redQuestions.length }}
                <ng-container *ngIf="redQuestions.length === 1; else areas">area that is broken</ng-container>
                <ng-template #areas>areas that are broken</ng-template>
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon *ngIf="!panelOpenState[2]">add</mat-icon>
                <mat-icon *ngIf="panelOpenState[2]">remove</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p *ngFor="let data of redQuestions" [innerHTML]="data.questionData?.question"></p>
          </mat-expansion-panel>
        </mat-accordion>
      </div><!-- report-expansion report-expansion -->

    </div><!-- report-block -->

    <!-- testimonial -->
    <div class="testimonial">
      <div class="testimonial-left" data-aos="zoom-in">
        <div class="image-bg"></div>
        <img src="../../assets/img/ben_coleman.jpg">
      </div>
      <div class="testimonial-right">
        <div class="testimonial-text" data-aos="fade-up" data-aos-delay="100">
          <!-- <span class="inverted-comma-start">“</span> -->
          <svg data-aos="zoom-in" data-aos-delay="100" class="quotation-open" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" x="0px" y="0px" width="508.044px" height="508.044px" viewBox="0 0 508.044 508.044" style="enable-background:new 0 0 508.044 508.044;" xml:space="preserve"> <g> <g> <path d="M0.108,352.536c0,66.794,54.144,120.938,120.937,120.938c66.794,0,120.938-54.144,120.938-120.938 s-54.144-120.937-120.938-120.937c-13.727,0-26.867,2.393-39.168,6.61C109.093,82.118,230.814-18.543,117.979,64.303 C-7.138,156.17-0.026,348.84,0.114,352.371C0.114,352.426,0.108,352.475,0.108,352.536z"></path> <path d="M266.169,352.536c0,66.794,54.144,120.938,120.938,120.938s120.938-54.144,120.938-120.938S453.9,231.599,387.106,231.599 c-13.728,0-26.867,2.393-39.168,6.61C375.154,82.118,496.875-18.543,384.04,64.303C258.923,156.17,266.034,348.84,266.175,352.371 C266.175,352.426,266.169,352.475,266.169,352.536z"></path> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
          Taking the ChurchOS Assessment was one of the first steps our team took when we decided to jump all in with Intentional Churches. We all took the test anonymously and compared our answers.
          <br/>
          <div style="height:10px;"></div>
          It helped us see where we were aligned and where there were divergent opinions. We began working with a better understanding of our strengths and weaknesses.
          <!-- <span class="inverted-comma-end">”</span> -->
          <svg data-aos="zoom-in" data-aos-delay="200" class="quotation-close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" x="0px" y="0px" width="508.044px" height="508.044px" viewBox="0 0 508.044 508.044" style="enable-background:new 0 0 508.044 508.044;" xml:space="preserve"> <g> <g> <path d="M507.93,155.673c0-0.055,0.006-0.11,0.006-0.165c0-66.793-54.145-120.938-120.938-120.938S266.061,88.714,266.061,155.508 c0,66.794,54.15,120.938,120.938,120.938c13.727,0,26.867-2.393,39.162-6.609c-27.209,156.09-148.93,256.752-36.096,173.905 C515.182,351.874,508.07,159.198,507.93,155.673z"></path> <path d="M120.938,276.445c13.727,0,26.867-2.393,39.168-6.609c-27.216,156.09-148.937,256.752-36.102,173.905 c125.117-91.867,118.006-284.543,117.865-288.068c0-0.055,0.006-0.11,0.006-0.165c0-66.793-54.144-120.938-120.937-120.938 C54.144,34.57,0,88.714,0,155.508C0,222.302,54.15,276.445,120.938,276.445z"></path> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
        </div>
        <div class="testimonial-name" data-aos="fade-up" data-aos-delay="300">
          Ben Coleman
        </div>
        <div class="testimonial-profile" data-aos="fade-up" data-aos-delay="400">
          Pastor of Adult Ministries, Sugar Creek Baptist Church
        </div>
      </div>
    </div>

    <!-- share-with-team  -->
    <div class="share-with-team" id="sharebtn" *ngIf="!preview && !hgc">
      <div class="share-with-team-title" data-aos="fade-up">Get your team to also take this assessment and compare notes!</div>
      <button data-aos="zoom-in" mat-button class="forty_parables_cta_btn ic-button ic-redbtn share-with-team-button" (click)="shareReport()">SHARE WITH MY TEAM</button>
    </div>

    <div class="share-with-team" id="sharebtn" *ngIf="!preview && hgc">
      <div class="share-with-team-title" data-aos="fade-up">Book a call with Tom to discuss your results and next steps.</div>
      <button data-aos="zoom-in" mat-button class="forty_parables_cta_btn ic-button ic-redbtn share-with-team-button" (click)="goToCalendly()">TALK WITH TOM</button>
    </div>

    <!-- Self discovery & Action -->
    <div class="report-block">

      <!-- title  -->
      <div class="block-title" data-aos="fade-up">
        Next steps
      </div>

      <!-- content -->
      <div class="block-content" data-aos="fade-up" data-aos-delay="100">
        Thank you for being intentional about evaluating your church. By investing time into thinking things through, you’ve taken a crucial step toward self-discovery.
      </div>

      <!-- next steps for you -->
      <div class="next-steps-block" data-aos="fade-up">

        <mat-tab-group>
          <mat-tab label="For you">
            <div class="next-step-wrapper">
              <div class="next-step-wrapper-left">
                  <span>1</span>
              </div>
              <div class="next-step-wrapper-right">
                <div class="next-step-wrapper-title">
                  See what’s working
                </div>
                <div class="next-step-wrapper-content">
                  Great self-discovery is driven by great questions. Consider the items in the green list and ask yourself:<br/>
                  <ul>
                    <li>What’s the strongest item that you can leverage?</li>
                    <li>What’s lower in rank and could use improvement or further investment?</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="next-step-wrapper">
              <div class="next-step-wrapper-left">
                  <span>2</span>
              </div>
              <div class="next-step-wrapper-right">
                <div class="next-step-wrapper-title">
                  See what needs work
                </div>
                <div class="next-step-wrapper-content">
                  Examine the red and yellow lists and highlight the biggest roadblocks. Consider what you would do about them.
                </div>
              </div>
            </div>
            <div class="next-step-wrapper">
              <div class="next-step-wrapper-left">
                  <span style="margin-top: -4px;">3</span>
              </div>
              <div class="next-step-wrapper-right">
                <div class="next-step-wrapper-title">
                  Get your team involved
                </div>
                <div class="next-step-wrapper-content">
                  We strongly recommend that you take this assessment as a team. <br/>
                  <ul>
                    <li>You’d have a common metric to evaluate the church so you’re on the same page.</li>
                    <li>You can all see the results in real time.</li>
                    <li>Walking through the results together will help you arrive at some actionable steps.</li>
                  </ul><br/>
                  <span id="sharelink">Click <a style="cursor: pointer" target="_blank" (click)="shareReport()">here</a> to share the assessment with your team.</span>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="For teams">
            <div class="next-step-wrapper">
              <div class="next-step-wrapper-left">
                  <span>1</span>
              </div>
              <div class="next-step-wrapper-right">
                <div class="next-step-wrapper-title">
                  Compare notes
                </div>
                <div class="next-step-wrapper-content">
                  When you take this assessment as a team, the first order of business for you all is to compare notes. <br/>
                  <ul>
                    <li>What was on everyone's list?</li>
                    <li>Did you vote or rank items similarly?</li>
                    <li>If not, why did you vote the way you voted?</li>
                  </ul><br>
                  As you begin the journey of self-discovery as a team, trust in the power of honest and objective conversations and decisions.	<br/>
                </div>
              </div>
            </div>
            <div class="next-step-wrapper">
              <div class="next-step-wrapper-left">
                  <span>2</span>
              </div>
              <div class="next-step-wrapper-right">
                <div class="next-step-wrapper-title">
                  Highlight what’s working
                </div>
                <div class="next-step-wrapper-content">
                  Go through the items in the green list and select:<br/>
                  <ul>
                    <li>The strongest item in your lists that you can leverage as a church</li>
                    <li>An item that’s lower in rank and needs improvement or further investment</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="next-step-wrapper">
              <div class="next-step-wrapper-left">
                  <span style="margin-top: -4px;">3</span>
              </div>
              <div class="next-step-wrapper-right">
                <div class="next-step-wrapper-title">
                  Discuss the roadblocks
                </div>
                <div class="next-step-wrapper-content">
                  Highlight the biggest roadblocks in the red and yellow lists, and discuss possible solutions with your team.
                </div>
              </div>
            </div>
            <div class="next-step-wrapper">
              <div class="next-step-wrapper-left">
                  <span style="margin-top: -5px;">4</span>
              </div>
              <div class="next-step-wrapper-right">
                <div class="next-step-wrapper-title">
                  Create an action plan
                </div>
                <div class="next-step-wrapper-content">
                  Assign a team with a leader to create an action plan for each prioritized item.
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="for-you-without-tab">
          <div class="for-you-without-tab-title">For you</div>
          <div class="next-step-wrapper">
            <div class="next-step-wrapper-left">
                <span>1</span>
            </div>
            <div class="next-step-wrapper-right">
              <div class="next-step-wrapper-title">
                See what’s working
              </div>
              <div class="next-step-wrapper-content">
                Great self-discovery is driven by great questions. Consider the items in the green list and ask yourself:<br/>
                <ul>
                  <li>What’s the strongest item that you can leverage?</li>
                  <li>What’s lower in rank and could use improvement or further investment?</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="next-step-wrapper">
            <div class="next-step-wrapper-left">
                <span>2</span>
            </div>
            <div class="next-step-wrapper-right">
              <div class="next-step-wrapper-title">
                See what needs work
              </div>
              <div class="next-step-wrapper-content">
                Examine the red and yellow lists and highlight the biggest roadblocks. Consider what you would do about them.
              </div>
            </div>
          </div>
          <div class="next-step-wrapper">
            <div class="next-step-wrapper-left">
                <span>3</span>
            </div>
            <div class="next-step-wrapper-right">
              <div class="next-step-wrapper-title">
                Get your team involved
              </div>
              <div class="next-step-wrapper-content">
                We strongly recommend that you take this assessment as a team. <br/>
                <ul>
                  <li>You’d have a common metric to evaluate the church so you’re on the same page.</li>
                  <li>You can all see the results in real time.</li>
                  <li>Walking through the results together will help you arrive at some actionable steps.</li>
                </ul><br/>
                <span id="sharelink">Click <a target="_blank" routerLink="/share/{{ uniqueId }}">here</a> to share the assessment with your team.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="for-teams-without-tab">
          <div class="for-you-without-tab-title">For teams</div>
          <div class="next-step-wrapper">
            <div class="next-step-wrapper-left">
                <span>1</span>
            </div>
            <div class="next-step-wrapper-right">
              <div class="next-step-wrapper-title">
                Compare notes
              </div>
              <div class="next-step-wrapper-content">
                When you take this assessment as a team, the first order of business for you all is to compare notes. <br/>
                <ul>
                  <li>What was on everyone's list?</li>
                  <li>Did you vote or rank items similarly?</li>
                  <li>If not, why did you vote the way you voted?</li>
                </ul><br>
                As you begin the journey of self-discovery as a team, trust in the power of honest and objective conversations and decisions.	<br/>
              </div>
            </div>
          </div>
          <div class="next-step-wrapper">
            <div class="next-step-wrapper-left">
                <span>2</span>
            </div>
            <div class="next-step-wrapper-right">
              <div class="next-step-wrapper-title">
                Highlight what’s working
              </div>
              <div class="next-step-wrapper-content">
                Go through the items in the green list and select:<br/>
                <ul>
                  <li>The strongest item in your lists that you can leverage as a church</li>
                  <li>An item that’s lower in rank and needs improvement or further investment</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="next-step-wrapper">
            <div class="next-step-wrapper-left">
                <span>3</span>
            </div>
            <div class="next-step-wrapper-right">
              <div class="next-step-wrapper-title">
                Discuss the roadblocks
              </div>
              <div class="next-step-wrapper-content">
                Highlight the biggest roadblocks in the red and yellow lists, and discuss possible solutions with your team.
              </div>
            </div>
          </div>
          <div class="next-step-wrapper">
            <div class="next-step-wrapper-left">
                <span>4</span>
            </div>
            <div class="next-step-wrapper-right">
              <div class="next-step-wrapper-title">
                Create an action plan
              </div>
              <div class="next-step-wrapper-content">
                Assign a team with a leader to create an action plan for each prioritized item.
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="next-step-title">Next steps for you</div>
        <div class="next-step-wrapper">
          <div class="next-step-wrapper-left">
              <span>1</span>
          </div>
          <div class="next-step-wrapper-right">
            <div class="next-step-wrapper-title">
              See what’s working
            </div>
            <div class="next-step-wrapper-content">
              Great self-discovery is driven by great questions. Consider the items in the green list and ask yourself:<br/>
              <ul>
                <li>What’s the strongest item that you can leverage?</li>
                <li>What’s lower in rank and could use improvement or further investment?</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="next-step-wrapper">
          <div class="next-step-wrapper-left">
              <span>2</span>
          </div>
          <div class="next-step-wrapper-right">
            <div class="next-step-wrapper-title">
              See what needs work
            </div>
            <div class="next-step-wrapper-content">
              Examine the red and yellow lists and highlight the biggest roadblocks. Consider what you would do about them.
            </div>
          </div>
        </div>
        <div class="next-step-wrapper">
          <div class="next-step-wrapper-left">
              <span>3</span>
          </div>
          <div class="next-step-wrapper-right">
            <div class="next-step-wrapper-title">
              Get your team involved
            </div>
            <div class="next-step-wrapper-content">
              We strongly recommend that you take this assessment as a team. <br/>
              <ul>
                <li>You’d have a common metric to evaluate the church so you’re on the same page.</li>
                <li>You can all see the results in real time.</li>
                <li>Walking through the results together will help you arrive at some actionable steps.</li>
              </ul><br/>
              Click <a routerLink="/share/{{ uniqueId }}">here</a> to share the assessment with your team.
            </div>
          </div>
        </div> -->

      </div>

      <!-- next steps for your team -->
      <!-- <div class="next-steps-block">
        <div class="next-step-title">Next steps for your team</div>
        <div class="next-step-wrapper">
          <div class="next-step-wrapper-left">
              <span>1</span>
          </div>
          <div class="next-step-wrapper-right">
            <div class="next-step-wrapper-title">
              Compare notes
            </div>
            <div class="next-step-wrapper-content">
              When you take this assessment as a team, the first order of business for you all is to compare notes. <br/>
              <ul>
                <li>What was on everyone's list?</li>
                <li>Did you vote or rank items similarly?</li>
                <li>If not, why did you vote the way you voted?</li>
              </ul><br>
              As you begin the journey of self-discovery as a team, trust in the power of honest and objective conversations and decisions.	<br/>
            </div>
          </div>
        </div>
        <div class="next-step-wrapper">
          <div class="next-step-wrapper-left">
              <span>2</span>
          </div>
          <div class="next-step-wrapper-right">
            <div class="next-step-wrapper-title">
              Highlight what’s working
            </div>
            <div class="next-step-wrapper-content">
              Go through the items in the green list and select:<br/>
              <ul>
                <li>The strongest item in your lists that you can leverage as a church</li>
                <li>An item that’s lower in rank and needs improvement or further investment</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="next-step-wrapper">
          <div class="next-step-wrapper-left">
              <span>3</span>
          </div>
          <div class="next-step-wrapper-right">
            <div class="next-step-wrapper-title">
              Discuss the roadblocks
            </div>
            <div class="next-step-wrapper-content">
              Highlight the biggest roadblocks in the red and yellow lists, and discuss possible solutions with your team.
            </div>
          </div>
        </div>
        <div class="next-step-wrapper">
          <div class="next-step-wrapper-left">
              <span>4</span>
          </div>
          <div class="next-step-wrapper-right">
            <div class="next-step-wrapper-title">
              Create an action plan
            </div>
            <div class="next-step-wrapper-content">
              Assign a team with a leader to create an action plan for each prioritized item.
            </div>
          </div>
        </div>
      </div> -->

    </div><!-- report-block -->

    <!-- testimonial -->
    <div class="testimonial">
      <div class="testimonial-left" data-aos="zoom-in">
        <div class="image-bg"></div>
        <img src="../../assets/img/matthew_robinson.jpg">
      </div>
      <div class="testimonial-right">
        <div class="testimonial-text" data-aos="fade-up" data-aos-delay="100">
          <!-- <span class="inverted-comma-start">“</span> -->
          <svg data-aos="zoom-in" data-aos-delay="100" class="quotation-open" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" x="0px" y="0px" width="508.044px" height="508.044px" viewBox="0 0 508.044 508.044" style="enable-background:new 0 0 508.044 508.044;" xml:space="preserve"> <g> <g> <path d="M0.108,352.536c0,66.794,54.144,120.938,120.937,120.938c66.794,0,120.938-54.144,120.938-120.938 s-54.144-120.937-120.938-120.937c-13.727,0-26.867,2.393-39.168,6.61C109.093,82.118,230.814-18.543,117.979,64.303 C-7.138,156.17-0.026,348.84,0.114,352.371C0.114,352.426,0.108,352.475,0.108,352.536z"></path> <path d="M266.169,352.536c0,66.794,54.144,120.938,120.938,120.938s120.938-54.144,120.938-120.938S453.9,231.599,387.106,231.599 c-13.728,0-26.867,2.393-39.168,6.61C375.154,82.118,496.875-18.543,384.04,64.303C258.923,156.17,266.034,348.84,266.175,352.371 C266.175,352.426,266.169,352.475,266.169,352.536z"></path> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
          The ChurchOS Assessment helped our team immediately plunge into meaningful conversation to identify quick action for focus and alignment.
          <br/>
          <div style="height:10px;"></div>
          This tool helped us make the most effective use of our Executive Retreat as a starting point for discussion and decision-making.
          <!-- <span class="inverted-comma-end">”</span> -->
          <svg data-aos="zoom-in" data-aos-delay="200" class="quotation-close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" x="0px" y="0px" width="508.044px" height="508.044px" viewBox="0 0 508.044 508.044" style="enable-background:new 0 0 508.044 508.044;" xml:space="preserve"> <g> <g> <path d="M507.93,155.673c0-0.055,0.006-0.11,0.006-0.165c0-66.793-54.145-120.938-120.938-120.938S266.061,88.714,266.061,155.508 c0,66.794,54.15,120.938,120.938,120.938c13.727,0,26.867-2.393,39.162-6.609c-27.209,156.09-148.93,256.752-36.096,173.905 C515.182,351.874,508.07,159.198,507.93,155.673z"></path> <path d="M120.938,276.445c13.727,0,26.867-2.393,39.168-6.609c-27.216,156.09-148.937,256.752-36.102,173.905 c125.117-91.867,118.006-284.543,117.865-288.068c0-0.055,0.006-0.11,0.006-0.165c0-66.793-54.144-120.938-120.937-120.938 C54.144,34.57,0,88.714,0,155.508C0,222.302,54.15,276.445,120.938,276.445z"></path> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
        </div>
        <div class="testimonial-name" data-aos="fade-up" data-aos-delay="200">
          Dr. Matthew Robinson
        </div>
        <div class="testimonial-profile" data-aos="fade-up" data-aos-delay="300">
          Pastor of Administration, First Baptist Orlando
        </div>
      </div>
    </div>

    <!-- share-with-team  -->
    <div class="share-with-team share-with-team-2" data-aos="fade-up" *ngIf="!preview">
      <div class="share-with-team-title">Share this report with your team and invite them to take the test.</div>
      <button mat-button class="forty_parables_cta_btn ic-button ic-redbtn share-with-team-button" (click)="shareReport()">SHARE WITH MY TEAM</button>
    </div>

  </div><!-- frame-small -->
</div><!-- report-page -->

<div class="matspinner" *ngIf="isLoading">
  <app-preloader style="width: 360px" [loadingTxt]="'Loading your report'"></app-preloader>
  <!-- <mat-spinner [strokeWidth]="7"></mat-spinner> -->
</div>
