import { OnInit, Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './shared/auth.service';
import { LightboxService } from './shared/lightbox.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  // Hide header
  showHeaderFooter            = false;
  showmenu                    = true;

  // Public URLs like login
  publicUrl                   = ['/churchos','/'];

  isMenuOpened                = false;

  innerWidth: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  showSubMenu                 = [false, false];

  public constructor(
    private auth: AuthService,
    private router: Router,
    public lightbox: LightboxService,
    ) { }

  ngOnInit() {

    this.innerWidth = window.innerWidth;

    // If this is a public page remove the header
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      ).subscribe(data => {

        if( this.publicUrl.includes( data['url'] ) ) {
          this.showHeaderFooter  = false;
        } else {
          this.showHeaderFooter  = true;
          if(data['url'].includes('confirmation')) {
            this.showmenu     = false;
          } else {
            this.showmenu     = true;
          }
          // if ( ! this.auth.isLoggedIn ) {
          //   this.router.navigate(["login"]);
          //   return  false;
          // }
        }
      });

  }

  toggleMenu() {
    this.isMenuOpened              = !this.isMenuOpened;
    if(this.isMenuOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }

  menuItemClick(index) {
    this.showSubMenu[index]        = !this.showSubMenu[index];
  }

  onActivate(event) {
    window.scroll(0,0);
  }

}
