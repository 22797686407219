import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LightboxService {

  showLightbox: boolean;
  lightboxTemplate: string;
  lightboxInfo: any;
  private reloadParent = new Subject<any>();
  private width: string = "";

  constructor() {
    this.showLightbox     = false;
    this.lightboxTemplate = "";
  }

  set lightboxWidth(width: string) {
    this.width = width;
  }

  get lightboxWidth(): string {
    return this.width || "25vw";
  }

  setReload(): Observable<any> {
    return this.reloadParent.asObservable();
  }

  public reload(template, data = {}){
    const RELOAD_INFO = {
      template,
      data
    };
    this.reloadParent.next(RELOAD_INFO);
  }

  close() {
    this.showLightbox         = false;
  }

}
