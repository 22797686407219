import { Injectable } from '@angular/core';
import { MatSidenav } from "@angular/material/sidenav";
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class AsideService {

  id:number;
	asideSetting = {
		id: 0,
		template: 'default'
  };
  
  private subject = new Subject<any>();
  private asidenav: MatSidenav;
  private reloadParent = new Subject<any>();

  public setSidenav(asidenav: MatSidenav) {
    this.asidenav = asidenav;
  }

  public open(id, template) {
    this.asideSetting = {
      id: id,
      template: template
    }
    this.subject.next(this.asideSetting);
    return this.asidenav.open();
  }

  public toggle(isOpen?: boolean) {
    console.log("working");
      return this.asidenav.toggle(isOpen);
  }

  public reload(template, data = {}){
    const RELOAD_INFO = {
      template,
      data
    }
    this.reloadParent.next(RELOAD_INFO);
  }

  constructor() {
  }

  getStatus(): Observable<any> {
    return this.subject.asObservable();
  }

  closingAside($event) {
		this.asideSetting = {
			id: -1,
			template: "reloadAside"
		};
		this.subject.next(this.asideSetting); 
	}

  setReload(): Observable<any> {
    return this.reloadParent.asObservable();
  }
}
