import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LightboxService } from 'src/app/shared/lightbox.service';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {

  @Input() lightboxInfo: any;
  @Output() onClose                 = new EventEmitter();

  constructor(
    private lightbox: LightboxService,
  ) { }

  ngOnInit(): void {
  }

  removeRecipient() {
    this.lightbox.reload("reloadShare", this.lightboxInfo.id);
    this.lightbox.close();
  }
}
