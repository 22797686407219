<main>

  <div class="login mat-elevation-z3">      
 
    <!-- Header -->
    <!-- -------- -->
    <div class="loginheader">
      <img src='./assets/img/logo.png'>
    </div>
    <h1 class="logintitle">Administrator Login</h1>
  
    <!-- Login Form -->
    <!-- ---------- -->
    <form [formGroup]="loginForm" (ngSubmit)="submitLogin()" novalidate>
      <mat-form-field>
        <input matInput placeholder="Email Address" formControlName="email" spellcheck="false" />
        <mat-error *ngIf="!!loginForm.get('email').errors?.required">
          Email is required.
        </mat-error>
        <mat-error *ngIf="!!loginForm.get('email').errors?.email">
          Invalid Email, Valid format is <strong>name@company.abc</strong>
        </mat-error>
      </mat-form-field>
  
      <mat-form-field>
        <input matInput placeholder="Password" formControlName="password" type="password"/>
        <mat-error *ngIf="!!loginForm.get('password').errors?.required">
          Password is required.
        </mat-error>
      </mat-form-field>
  
      <!-- Action Button -->
      <!-- -------------- -->
      <div class="actionBtn">
        <button mat-raised-button type="submit" class='redbtn' [disabled]="loginForm.invalid || isSubmitting">{{ loginbtn }}</button>
      </div>
    
    </form>
  
  </div>

</main>
