import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../shared/api.service';
import { UiService } from '../shared/ui.service';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as AOS from 'aos';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  panelOpenState              = [true, false, false];
  isLoading                   = true;
  isDownloading               = false;
  downloadBtn                 = "Download";

  uniqueId                    = null;
  preview                     = false;
  questions                   = [];
  firstName                   = null;
  lastName                    = null;
  email                       = null;
  formData                    = [];
  score                       = 0;
  perScore                    = 0;
  redQuestions                = [];
  yellowQuestions             = [];
  greenQuestions              = [];

  hgc                         = false;

  constructor(
    private api: ApiService,
    private ui: UiService,
    private router: Router,
    private activeroute: ActivatedRoute
  ) {
    AOS.init({
      'once': true
    });
  }

  ngOnInit(): void {
    // this.uniqueId            = this.activeroute.snapshot.queryParamMap.get('unique_id');
    // this.uniqueId             = parseInt(this.activeroute.snapshot.params['uniqueid']);
    this.uniqueId             = this.activeroute.snapshot.params['uniqueid'];
    if(this.router.url.includes('hgc')) {
      this.hgc                = true;
    }

    if(this.activeroute.snapshot.params['view'] == "preview") {
      this.preview            = true;
    } else if(this.activeroute.snapshot.params['view'] == "share") {
      this.router.navigate(['/share/' + this.uniqueId]);
    }
    this.fetchData();
  }

  fetchData() {

    let questions                         = this.api.get('v1/form/questions');
    let formData                          = this.api.get('v1/form/' + this.uniqueId);
    console.log("fetching");
    forkJoin([questions, formData])
      .subscribe(results => {
        console.log(results);
        if(results[0].status == "success" && results[1].status == "success"){
          this.questions                  = results[0].data;
          this.firstName                  = results[1].firstName;
          this.lastName                   = results[1].lastName;
          this.email                      = results[1].email;
          this.formData                   = results[1].data;
          this.calculateQuestions();
          this.calculateScore();
          this.isLoading                  = false;
          /*if(this.activeroute.snapshot.params['view'] == "share") {
            //console.log("here");
            /*setTimeout(() => {
              document.querySelector('#sharebtn').scrollIntoView(
                { behavior: 'smooth' }
              );
            }, 100);*/
          //}
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
      });

  }

  calculateScore() {

    let noOfQuestions         = this.questions.length;
    let totalScore            = noOfQuestions*6; // 126

    let noOfRed               = this.redQuestions.length;
    let noOfYellow            = this.yellowQuestions.length;
    let noOfGreen             = this.greenQuestions.length;

    this.score                = noOfRed + noOfYellow*3 + noOfGreen*6;
    this.perScore             = Math.round((noOfRed + noOfYellow*3 + noOfGreen*6)/totalScore * 100);

  }

  calculateQuestions() {
    this.redQuestions               = this.formData
                                      .filter(data => data.answer == 1)
                                      .map(data => ({
                                        ...data,
                                        questionData: this.questions.find((obj) => { return obj.id == data.questionId })
                                      }));
    this.yellowQuestions            = this.formData
                                      .filter(data => data.answer == 3)
                                      .map(data => ({
                                        ...data,
                                        questionData: this.questions.find((obj) => { return obj.id == data.questionId })
                                      }));
    this.greenQuestions             = this.formData
                                      .filter(data => data.answer == 6)
                                      .map(data => ({
                                        ...data,
                                        questionData: this.questions.find((obj) => { return obj.id == data.questionId })
                                      }));
  }

  openPanel(index) {
    this.panelOpenState[index] = true;
  }

  closePanel(index) {
    this.panelOpenState[index] = false;
  }

  print() {
    window.print();
  }

  download() {

    this.downloadBtn            = "Preparing download...";

    this.api.get('v1/print/' + this.uniqueId)
      .subscribe(data => {
        if(data.status == "success") {
          this.downloadBtn      = "Downloading...";
          location.href         = "https://api-ic.40parables.com/download.php?uid=" + this.uniqueId;
          setTimeout(() => {
            this.downloadBtn    = "Download";
          }, 1000);
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
      });

    /*this.panelOpenState     = this.panelOpenState.map(val => true);
    this.isDownloading      = true;

    setTimeout(() => {
      this.savePdf();
    }, 1);*/
  }

  savePdf() {
    var data = document.getElementsByTagName('body')[0];
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('church-assessment-report-' + Date.now() + '.pdf');

      this.panelOpenState     = this.panelOpenState.map((val, index) => index === 0 ? true : false);
      this.isDownloading      = false;
    });
  }

  shareReport() {
    if(this.hgc) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/hgc-share/' + this.uniqueId])
      );
      window.open(url, '_blank');
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/share/' + this.uniqueId])
      );
      window.open(url, '_blank');
    }
  }

  goToCalendly() {
    window.open(
      'https://calendly.com/planck/',
      '_blank'
    );
  }
}
