import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  uniqueId: any;

  constructor(
    private activeroute: ActivatedRoute ) { }

  ngOnInit(): void {
    this.uniqueId             = parseInt(this.activeroute.snapshot.params['uniqueid']);
  }

}
