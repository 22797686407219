import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';


import { LightboxService } from '../shared/lightbox.service';
import { AsideService } from '../shared/aside.service';


const ELEMENT_DATA = [
  {title: 'Hydrogen', status: 'draft', responses: '-'},
  {title: 'Helium', status: 'draft', responses: '-'},
  {title: 'Lithium', status: 'draft', responses: '-'},
  {title: 'Beryllium', status: 'draft', responses: '-'},
  {title: 'Boron', status: 'running', responses: '25'},
  {title: 'Carbon', status: 'running', responses: '68'},
  {title: 'Nitrogen', status: 'closed', responses: '36'},
  {title: 'Oxygen', status: 'closed', responses: '251'},
  {title: 'Fluorine', status: 'closed', responses: '968'},
  {title: 'Neon', status: 'closed', responses: '598'}
];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // Mat variables
  displayedColumns 					= ['title','status','responses','tools'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  
  constructor(
    public lightbox: LightboxService,
    public aside: AsideService ) { }

  ngOnInit() {}

  
  statusCell(status){
    if (status == 'draft'){
      return "<span class='draft'>draft</span>";
    } else if (status == 'running'){
      return "<span class='running'>running</span>";
    } else if (status == 'closed'){
      return "<span class='closed'>closed</span>";
    }
  }

  // Open the aside with the stipulated template
  // -------------------------------------------
  public openAside(id, template) {
    this.aside.open(id, template);
  }

}
