<div class="share-page">
  <div class="frame-small">

    <div class="share-wrapper">

      <!-- logo -->
      <div class="share-logo">
        <img src='./../../assets/img/logo-assessment-new-black.png'>
      </div>

      <!-- title -->
      <div class="share-title">
        Invite your team to<br/>
        take the assessment
      </div>

      <!-- share-subtitle -->
      <div class="share-subtitle">
        Add their details and we'll email them the assessment.
      </div>

      <!-- main form -->
      <div class="share-form">
        <!-- <div class="form-title">Share via email directly</div> -->

        <!-- form -->
        <form [formGroup]='shareForm' >
          <div formArrayName="data">
            <div class="form-fields-wrapper" *ngFor="let fieldData of shareForm.get('data')['controls']; let i = index" [formGroupName]="i">
              <mat-form-field appearance="outline" floatLabel="never" class="first-name">
                <input matInput placeholder="Full Name" formControlName="firstName">
                <mat-error *ngIf="!!fieldData.get('firstName').errors?.required">Please enter their name.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="never" class="email">
                <input matInput placeholder="johndoe@example.com" formControlName="email">
                <mat-error *ngIf="!!fieldData.get('email').errors?.required">
                  Please enter their email.
                </mat-error>
                <mat-error *ngIf="!!fieldData.get('email').errors?.email">
                  Please enter valid email.
                </mat-error>
              </mat-form-field>
              <ng-container *ngIf="i != (fields-1)">
                <span class="delete material-icons" (click)="confirmationPopup(i)" matTooltip="Remove" matTooltipPosition="above">delete</span>
              </ng-container>
              <ng-container *ngIf="i == (fields-1)">
                <span class="delete material-icons" (click)="fields === 1 ? '' : confirmationPopup(i)" [class.field-disabled]="fields === 1" [matTooltip]="fields === 1 ? '' : 'Remove'" matTooltipPosition="above">delete</span>
                <button mat-button class="ic-button ic-redbtn add-button" (click)="addRecipient(i)"><span class="material-icons">add</span>ADD</button>
              </ng-container>
            </div><!-- form-fields-wrapper -->
          </div>
        </form>

        <!-- checkbox -->
        <div class="share-result">
          <mat-checkbox [(ngModel)]="shareResults">Include my results in the email.</mat-checkbox>
        </div>

        <!-- button -->
        <div class="share-btn-block" style="text-align: center;">
          <button mat-button class="forty_parables_cta_btn ic-button ic-redbtn share-button" (click)="shareForm.invalid ? showErrors() : share()" [class.circle-btn]="isSubmitting">
            <img class="submit-loader" *ngIf="isSubmitting" src="./../../assets/img/submit.gif" />
            <span *ngIf="!isSubmitting">SHARE WITH MY TEAM</span>
          </button>
        </div>

      </div><!-- share-form -->

    </div><!-- share-wrapper -->

  </div><!-- frame-small -->
</div><!-- share-page -->
