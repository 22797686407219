import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from '../shared/api.service';
import { UiService } from '../shared/ui.service';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  // Loading booleans
  isSubmitting              = false;

  // Template dynamic content
  loginbtn                  = 'Login';

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private auth: AuthService,
    private ui: UiService,
    private router: Router ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email:    ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  submitLogin() {
    const payload = this.loginForm.value;

    // Update button and snackbar
    this.loginbtn               = "Logging in... Please wait";
    this.isSubmitting           = true;
    this.ui.showSnackbar("Logging in... Please wait", "pending");

    this.api.post('adminlogin', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          // Authenticate the user
          this.auth.setToken(data.token);
          this.auth.setUserId(data.userId);
          this.auth.setUserScope(data.userScope);
          this.auth.setUserName(data.userName);
          this.auth.setUserEmail(data.userEmail);

          // Update button and snackbar
          this.loginbtn         = "Redirecting...";
          this.ui.showSnackbar("Logged in successfully", "success");

          // Redirect to the overview page
          this.router.navigate(['/overview']);

        } else {
          this.loginbtn         = "Login";
          this.isSubmitting     = false;
          this.ui.showSnackbar("Sorry login details incorrect", "failure");
        }
      }, err => {
        console.log(err.statusCode);
        if(err.statusCode == 401) {
          this.isSubmitting     = false;
          this.loginbtn         = "Login";
          this.ui.showSnackbar("Sorry login details incorrect", "failure");
        } else {
          this.isSubmitting     = false;
          this.loginbtn         = "Login";
          this.ui.showSnackbar("Network Error. Please check your internet connection", "failure");
        }
      });

  }

}
