<!-- Header -->
<!-- ======
<mat-toolbar class="header">
  <mat-toolbar-row>
    <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    <img src='/assets/img/logo1.png' style='height: 64px;'>
  </mat-toolbar-row>
</mat-toolbar>

<mat-tab-group>

  <mat-tab label="Surveys"> -->

    <main>

      <div class='container'>
          <div style='height: 30px;'></div>
          <mat-toolbar>
            <mat-toolbar-row class='pagetitle'>
                <h1>Table</h1>
                <span class="toolbarspacer"></span>
                <button mat-raised-button class='greybtn' (click)="openAside(1, 'update')">Update</button>
                <button mat-raised-button class='redbtn' (click)="openAside(1, 'create')">Create</button>
                <button mat-button class='filterbtn'>Filter</button>
            </mat-toolbar-row>
          </mat-toolbar>

          <div class="table-container mat-elevation-z3">

            <mat-table #table [dataSource]="dataSource">

              <!-- Survey Title -->
              <!-- ------------ -->
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
              </ng-container>


              <!-- Status Title -->
              <!-- ------------ -->
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let element" [innerHTML]="statusCell(element.status)"></mat-cell>
              </ng-container>


              <!-- Survey Title -->
              <!-- ------------ -->
              <ng-container matColumnDef="responses">
                <mat-header-cell *matHeaderCellDef> Responses </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.responses }}</mat-cell>
              </ng-container>


              <!-- Tools Column -->
              <!-- ------------ -->
              <ng-container matColumnDef="tools">
                <mat-header-cell *matHeaderCellDef> &nbsp;</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <mat-icon>play_arrow</mat-icon>
                  <mat-icon>more_vert</mat-icon>

              </mat-cell>
              </ng-container>

			  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>

          </div>

      </div>

    </main>

  <!-- </mat-tab>

  <mat-tab label="Contacts">

  </mat-tab>

  <mat-tab label="Reports">

  </mat-tab>

</mat-tab-group>-->

