import { Routes, RouterModule } from "@angular/router";

// App Component.
// -------------
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ShareComponent } from "./share/share.component";
import { ReportComponent } from "./report/report.component";
import { ConfirmationComponent } from "./share/confirmation/confirmation.component";
import { ChurchosComponent } from "./churchos/churchos.component";


export const router: Routes = [

    { path: '', redirectTo: 'churchos', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'overview', component: DashboardComponent},
    { path: 'share', component: ShareComponent},
    { path: 'share/confirmation', component: ConfirmationComponent},
    { path: 'report', component: ReportComponent },
    { path: 'report/:uniqueid', component: ReportComponent },
    { path: 'report/:uniqueid/:view', component: ReportComponent },
    { path: 'hgc-report', component: ReportComponent },
    { path: 'hgc-report/:uniqueid', component: ReportComponent },
    { path: 'hgc-report/:uniqueid/:view', component: ReportComponent },
    { path: 'share/:uniqueid', component: ShareComponent },
    { path: 'hgc-share/:uniqueid', component: ShareComponent },
    { path: 'confirmed/:uniqueid', component: ConfirmationComponent },
    { path: 'churchos', component: ChurchosComponent },
    { path: 'churchos/hgc', component: ChurchosComponent },
    { path: '**', component: PageNotFoundComponent }

];

export const routes = RouterModule.forRoot(router, { relativeLinkResolution: 'legacy' });
