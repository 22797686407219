<div style="width: 200px;height: 200px;margin: -50px auto 0;">

  <section class='forty_parables_arrow_container'>
    <div class='item'><div class='square animate10'></div></div>
    <div class='item'><div class='square animate11'></div></div>
    <div class='item'><div class='square animate12'></div></div>
    <div class='item'><div class='square animate13'></div></div>
    <div class='item'><div class='square animate14'></div></div>
    <div class='item'><div class='square animate15'></div></div>
    <div class='item'><div class='square animate16'></div></div>
    <div class='item'><div class='square animate17'></div></div>
    <div class='item'><div class='square animate18'></div></div>
    <div class='item'><div class='square reddot animatered'></div></div>
    <div class='item'><div class='square animate9'></div></div>
    <div class='item'><div class='square arrow animate10'></div></div>
    <div class='item'><div class='square arrow animate11'></div></div>
    <div class='item'><div class='square arrow animate12'></div></div>
    <div class='item'><div class='square arrow animate13'></div></div>
    <div class='item'><div class='square arrow animate14'></div></div>
    <div class='item'><div class='square arrow animate15'></div></div>
    <div class='item'><div class='square arrow animate16'></div></div>
    <div class='item'><div class='square arrow animate17'></div></div>
    <div class='item'><div class='square animate18'></div></div>
    <div class='item'><div class='square animate8'></div></div>
    <div class='item'><div class='square arrow animate9'></div></div>
    <div class='item'><div class='square arrow animate10'></div></div>
    <div class='item'><div class='square arrow animate11'></div></div>
    <div class='item'><div class='square arrow animate12'></div></div>
    <div class='item'><div class='square arrow animate13'></div></div>
    <div class='item'><div class='square arrow animate14'></div></div>
    <div class='item'><div class='square arrow animate15'></div></div>
    <div class='item'><div class='square arrow animate16'></div></div>
    <div class='item'><div class='square animate17'></div></div>
    <div class='item'><div class='square animate7'></div></div>
    <div class='item'><div class='square animate8'></div></div>
    <div class='item'><div class='square animate9'></div></div>
    <div class='item'><div class='square animate10'></div></div>
    <div class='item'><div class='square animate11'></div></div>
    <div class='item'><div class='square arrow animate12'></div></div>
    <div class='item'><div class='square arrow animate13'></div></div>
    <div class='item'><div class='square arrow animate14'></div></div>
    <div class='item'><div class='square arrow animate15'></div></div>
    <div class='item'><div class='square animate16'></div></div>
    <div class='item'><div class='square animate6'></div></div>
    <div class='item'><div class='square animate7'></div></div>
    <div class='item'><div class='square animate8'></div></div>
    <div class='item'><div class='square animate9'></div></div>
    <div class='item'><div class='square arrow animate10'></div></div>
    <div class='item'><div class='square arrow animate11'></div></div>
    <div class='item'><div class='square arrow animate12'></div></div>
    <div class='item'><div class='square arrow animate13'></div></div>
    <div class='item'><div class='square arrow animate14'></div></div>
    <div class='item'><div class='square animate15'></div></div>
    <div class='item'><div class='square animate5'></div></div>
    <div class='item'><div class='square animate6'></div></div>
    <div class='item'><div class='square animate7'></div></div>
    <div class='item'><div class='square arrow animate8'></div></div>
    <div class='item'><div class='square arrow animate9'></div></div>
    <div class='item'><div class='square arrow animate10'></div></div>
    <div class='item'><div class='square animate11'></div></div>
    <div class='item'><div class='square arrow animate12'></div></div>
    <div class='item'><div class='square arrow animate13'></div></div>
    <div class='item'><div class='square animate14'></div></div>
    <div class='item'><div class='square animate4'></div></div>
    <div class='item'><div class='square animate5'></div></div>
    <div class='item'><div class='square arrow animate6'></div></div>
    <div class='item'><div class='square arrow animate7'></div></div>
    <div class='item'><div class='square arrow animate8'></div></div>
    <div class='item'><div class='square animate9'></div></div>
    <div class='item'><div class='square animate10'></div></div>
    <div class='item'><div class='square arrow animate11'></div></div>
    <div class='item'><div class='square arrow animate12'></div></div>
    <div class='item'><div class='square animate13'></div></div>
    <div class='item'><div class='square animate3'></div></div>
    <div class='item'><div class='square arrow animate4'></div></div>
    <div class='item'><div class='square arrow animate5'></div></div>
    <div class='item'><div class='square arrow animate6'></div></div>
    <div class='item'><div class='square animate7'></div></div>
    <div class='item'><div class='square animate8'></div></div>
    <div class='item'><div class='square animate9'></div></div>
    <div class='item'><div class='square arrow animate10'></div></div>
    <div class='item'><div class='square arrow animate11'></div></div>
    <div class='item'><div class='square animate12'></div></div>
    <div class='item'><div class='square arrow animate2'></div></div>
    <div class='item'><div class='square arrow animate3'></div></div>
    <div class='item'><div class='square arrow animate4'></div></div>
    <div class='item'><div class='square animate5'></div></div>
    <div class='item'><div class='square animate6'></div></div>
    <div class='item'><div class='square animate7'></div></div>
    <div class='item'><div class='square animate8'></div></div>
    <div class='item'><div class='square arrow animate9'></div></div>
    <div class='item'><div class='square arrow animate10'></div></div>
    <div class='item'><div class='square animate11'></div></div>
    <div class='item'><div class='square arrow animate1'></div></div>
    <div class='item'><div class='square arrow animate2'></div></div>
    <div class='item'><div class='square animate3'></div></div>
    <div class='item'><div class='square animate4'></div></div>
    <div class='item'><div class='square animate5'></div></div>
    <div class='item'><div class='square animate6'></div></div>
    <div class='item'><div class='square animate7'></div></div>
    <div class='item'><div class='square animate8'></div></div>
    <div class='item'><div class='square animate9'></div></div>
    <div class='item'><div class='square animate10'></div></div>
  </section>
  <div class="preloader-title">{{ loadingTxt }}</div>

</div>
