
<div class="lightbox"
     [ngSwitch]="lightboxTemplate"
     [style.width]="lightboxWidth">

  <div *ngSwitchCase="'confirmationPopup'">
    <app-confirmation-popup [lightboxInfo]="lightboxInfo" (onClose)="onClose.emit()"></app-confirmation-popup>
  </div>

  <div *ngSwitchDefault>
    <div class="defaultLightbox">
      <h1>Lightbox</h1>
      <div><p>{{dummyText}}</p></div>
      <div>
        <button (click)="onClose.emit()" mat-button >Close</button>
        <button mat-button>Save</button>
      </div>
    </div>
  </div>
</div>
