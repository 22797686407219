<div class="matspinner" *ngIf="isLoading">
  <app-preloader style="width: 360px" [loadingTxt]="loadingTxt"></app-preloader>
  <!-- <mat-spinner [strokeWidth]="7"></mat-spinner> -->
</div>

<div class="churchos-page" *ngIf="!isLoading">

  <!-- first screen -->
  <div class="churchos-first-screen screen-block" id="screen1" [class.visible]="screens[0]">

    <div class="frame-medium">

      <!-- logo -->
      <div class="churchos-assessment-logo-wrapper">
        <img src="../../assets/img/logo-arrow.png" data-aos="fade-up">
        <div class="churchos-assessment-logo">
          <div class="churchos-title" data-aos="fade-up" data-aos-delay="100">ChurchOS</div>
          <div class="churchos-subtitle" data-aos="fade-up" data-aos-delay="150">ASSESSMENT</div>
        </div>
      </div>

      <!-- image -->
      <div class="churchos-flag-image" data-aos="fade-up" data-aos-delay="200">
        <div style="max-width: 557px; width: 100%;margin: 0 auto;">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 557 148">
            <title>Group 4</title>
            <g id="Wireframes" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Church-Os" transform="translate(-474.000000, -8467.000000)">
                <g id="Group-4" transform="translate(474.000000, 8467.000000)">
                  <g id="flag" transform="translate(42.064375, 87.000000)" fill-rule="nonzero">
                    <path d="M29.0331157,15 L31.8310383,7.60990435 C31.9680011,7.24818261 31.9261493,6.83833043 31.7194829,6.51581739 C31.5127424,6.19322609 31.1680761,6 30.7994099,6 L16.7253524,6 C16.1117235,6 15.6142426,6.5256 15.6142426,7.17391304 L15.6142426,17.7391304 L13.0216531,17.7391304 C12.4080242,17.7391304 11.9105433,18.2647304 11.9105433,18.9130435 L11.9105433,22.826087 C11.9105433,23.4744 12.4080242,24 13.0216531,24 L30.7994099,24 C31.1681502,24 31.5127424,23.8067739 31.7194829,23.4841826 C31.9262234,23.1616696 31.9680011,22.7518174 31.8310383,22.3900957 L29.0331157,15 Z" id="Path" fill="#EF382E"></path>
                    <path d="M13.0353207,24 C12.8904204,24 12.7442451,23.972025 12.6050448,23.91435 C12.1846689,23.7402 11.9105433,23.330025 11.9105433,22.875 L11.9105433,19.125 C11.9105433,18.5037 12.4142444,18 13.0355457,18 L16.7855537,18 C17.2405797,18 17.6508305,18.27405 17.8249059,18.6945 C17.9989813,19.114875 17.9027561,19.598775 17.5810054,19.920525 L13.8309974,23.670525 C13.6158219,23.885775 13.3280463,24 13.0353207,24 Z" id="Path" fill="#BB2C24"></path>
                    <path d="M17.7366303,3 L2.08445636,3 C1.43614331,3 0.910543313,3.5256 0.910543313,4.17391304 L0.910543313,19.826087 C0.910543313,20.4744 1.43614331,21 2.08445636,21 L17.7366303,21 C18.3849433,21 18.9105433,20.4744 18.9105433,19.826087 L18.9105433,4.17391304 C18.9105433,3.5256 18.3849433,3 17.7366303,3 Z" id="Path" fill="#EE847E"></path>
                    <path d="M2.41054331,61 C1.58214331,61 0.910543313,60.1998516 0.910543313,59.2128906 L0.910543313,1.78710938 C0.910543313,0.800148437 1.58214331,0 2.41054331,0 C3.23894331,0 3.91054331,0.800148437 3.91054331,1.78710938 L3.91054331,59.2128906 C3.91054331,60.1998516 3.23894331,61 2.41054331,61 Z" id="Path" fill="#A4B0BE"></path>
                  </g>
                  <g id="flag-copy" transform="translate(512.064375, 0.000000)" fill-rule="nonzero">
                    <path d="M29.0331157,15 L31.8310383,7.60990435 C31.9680011,7.24818261 31.9261493,6.83833043 31.7194829,6.51581739 C31.5127424,6.19322609 31.1680761,6 30.7994099,6 L16.7253524,6 C16.1117235,6 15.6142426,6.5256 15.6142426,7.17391304 L15.6142426,17.7391304 L13.0216531,17.7391304 C12.4080242,17.7391304 11.9105433,18.2647304 11.9105433,18.9130435 L11.9105433,22.826087 C11.9105433,23.4744 12.4080242,24 13.0216531,24 L30.7994099,24 C31.1681502,24 31.5127424,23.8067739 31.7194829,23.4841826 C31.9262234,23.1616696 31.9680011,22.7518174 31.8310383,22.3900957 L29.0331157,15 Z" id="Path" fill="#EF382E"></path>
                    <path d="M13.0353207,24 C12.8904204,24 12.7442451,23.972025 12.6050448,23.91435 C12.1846689,23.7402 11.9105433,23.330025 11.9105433,22.875 L11.9105433,19.125 C11.9105433,18.5037 12.4142444,18 13.0355457,18 L16.7855537,18 C17.2405797,18 17.6508305,18.27405 17.8249059,18.6945 C17.9989813,19.114875 17.9027561,19.598775 17.5810054,19.920525 L13.8309974,23.670525 C13.6158219,23.885775 13.3280463,24 13.0353207,24 Z" id="Path" fill="#BB2C24"></path>
                    <path d="M17.7366303,3 L2.08445636,3 C1.43614331,3 0.910543313,3.5256 0.910543313,4.17391304 L0.910543313,19.826087 C0.910543313,20.4744 1.43614331,21 2.08445636,21 L17.7366303,21 C18.3849433,21 18.9105433,20.4744 18.9105433,19.826087 L18.9105433,4.17391304 C18.9105433,3.5256 18.3849433,3 17.7366303,3 Z" id="Path" fill="#EE847E"></path><path d="M2.41054331,61 C1.58214331,61 0.910543313,60.1998516 0.910543313,59.2128906 L0.910543313,1.78710938 C0.910543313,0.800148437 1.58214331,0 2.41054331,0 C3.23894331,0 3.91054331,0.800148437 3.91054331,1.78710938 L3.91054331,59.2128906 C3.91054331,60.1998516 3.23894331,61 2.41054331,61 Z" id="Path" fill="#A4B0BE"></path>
                  </g>
                  <path d="M41.9749179,147 C181.879081,85.6666667 338.657695,55 512.310762,55" id="Path-20" stroke="#CECECE" stroke-dasharray="5"></path>
                  <g id="Group-3" stroke="#EF382E" stroke-linecap="round" stroke-width="3">
                    <path d="M2.81638069,-7.27595761e-12 L11.836796,-7.27595761e-12 C12.3890807,-7.27605907e-12 12.836796,0.44771525 12.836796,1 L12.836796,9.07347092 L12.836796,9.07347092" id="Path-12"></path>
                    <line x1="10.5" y1="2.33395404" x2="0.745039542" y2="11.5266851" id="Line"></line>
                  </g>
                  <path d="M-4.54747351e-13,147 L133.758864,147 L219.428845,117.5 C247.217428,139.934926 264.0931,147.049189 270.05586,138.842788 C276.01862,130.636388 289.116892,116.025684 309.350675,95.0106762 L405.000981,90 L436.982708,75.5358588 L484.762037,69.2359772 L502.272005,61 L556.271454,61" id="Path-21" stroke="#CDCDCD"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>

      <!-- content -->
      <div class="churchos-content">
        <div class="churchos-heading" data-aos="fade-up" data-aos-delay="300">
          Know where your church is today to<br/>
          know where it needs to go tomorrow
        </div>
        <div class="churchos-subheading" data-aos="fade-up" data-aos-delay="400">
          Assess how your church is faring in these 21 aspects to <br/>
          activate the Great Commission in your community.
        </div>
        <button data-aos="fade-up" data-aos-delay="500" data-aos-offset="-300" mat-button class="forty_parables_cta_btn ic-button ic-redbtn get-started" (click)="openScreen(2)">GET STARTED</button>
      </div>

    </div><!-- frame -->

  </div><!-- hurchos-first-screen -->

  <!-- second screen -->
  <div class="churchos-second-screen screen-block" id="screen2" [class.visible]="screens[1]">

    <div class="frame-small">
      <div class="churchos-ss-title">BEFORE YOU START</div>
      <div class="churchos-ss-content">For each of the following 21 church benchmarks, choose one of these three responses that accurately reflects your church’s current situation.</div>
      <div class="churchos-ss-info-wrapper">

        <div class="churchos-ss-info-block">
          <div class="churchos-ss-info-left"></div>
          <div class="churchos-ss-info-right">
            The benchmark is not in position to activate the Great Commission or multiply Kingdom impact.
            It is potentially broken or barely functioning to these standards, and demands attention.
          </div>
        </div>

        <div class="churchos-ss-info-block">
          <div class="churchos-ss-info-left"></div>
          <div class="churchos-ss-info-right">
            The benchmark affects Great Commission activation and multiplies Kingdom impact but has room for improvement.
            It is not at its top level of effectiveness, and needs further action to become optimal.
          </div>
        </div>

        <div class="churchos-ss-info-block">
          <div class="churchos-ss-info-left"></div>
          <div class="churchos-ss-info-right">
            The benchmark activates the Great Commission and multiplies Kingdom impact. It is effective and healthy,
            but might need slight improvement. Ensure it has the resources and attention it needs.
          </div>
        </div>

      </div><!-- churchos-ss-info-wrapper -->

      <button mat-button class="forty_parables_cta_btn ic-button ic-redbtn get-started" (click)="openScreen(3)">LET'S BEGIN</button>

    </div><!-- frame-small -->

  </div><!-- churchos-second-screen -->

  <!-- third screen -->
  <div class="churchos-third-screen screen-block" id="screen3" [class.visible]="screens[2]">

    <div class="frame-medium">

      <!-- counter -->
      <div class="churchos-ts-counter" *ngIf="!displayForm">
        <span>{{ currentQuestion }}</span>
      </div>

       <!-- title -->
       <div class="churchos-ts-title" *ngIf="displayForm">
        Now that's intentional!
      </div>

      <!-- progress bar -->
      <div class="churchos-ts-progress-bar">
        <mat-progress-bar mode="determinate" [value]="getPercentage()"></mat-progress-bar>
        <div class="churchos-percentage">
          <span [style.margin-left.%]="getPercentage() > 10 ? getPercentage() - 10 : 0" *ngIf="!displayForm">
            {{ getPercentage() }}%
          </span>
        </div>
      </div>

      <ng-container *ngFor="let question of questions; let i = index">

        <!-- question details -->
        <div class="churchos-ts-question-wrapper" [innerHTML]="removeColon(question.question)" *ngIf="currentQuestion === i+1 && !displayForm">
        </div>

        <!-- radio btn -->
        <div class="churchos-ts-radio" *ngIf="currentQuestion === i+1 && !displayForm">
          <mat-radio-group [(ngModel)]="question.answer" (ngModelChange)="switchQuestion(currentQuestion+1, true)">
            <mat-radio-button value="1" (click)="switchQuestion(currentQuestion+1, true, '1')">
              <div class="churchos-ts-radio-left">
                <span class="material-icons">check_circle</span>
              </div>
              <div class="churchos-ts-radio-right">Broken</div>
            </mat-radio-button>
            <mat-radio-button value="2" (click)="switchQuestion(currentQuestion+1, true, '2')">
              <div class="churchos-ts-radio-left">
                <span class="material-icons">check_circle</span>
              </div>
              <div class="churchos-ts-radio-right" >Needs improvement</div>
            </mat-radio-button>
            <mat-radio-button value="3" (click)="switchQuestion(currentQuestion+1, true, '3')">
              <div class="churchos-ts-radio-left" >
                <span class="material-icons">check_circle</span>
              </div>
              <div class="churchos-ts-radio-right">Activating impact</div>
            </mat-radio-button>
          </mat-radio-group>
        </div>

      </ng-container>

      <div class="churchos-ts-error" [style.visibility]="questionError && !displayForm ? 'visible' : 'hidden'">
        You need to select at least one option.
      </div>

      <!-- previous & next btns -->
      <div class="previous-next-btn" *ngIf="!displayForm">
        <a (click)="switchQuestion(currentQuestion-1)" [style.visibility]="currentQuestion > 1 ? 'visible' : 'hidden'">
          <span class="material-icons">keyboard_arrow_left</span>
          Previous
        </a>
        <a (click)="switchQuestion(currentQuestion+1)" class="next-btn">
          Next
          <span class="material-icons">keyboard_arrow_right</span>
        </a>
      </div>

      <!-- form -->
      <div class="churchos-ts-form-wrapper" *ngIf="displayForm">

        <div class="churchos-ts-form-content">
          Before we get to your report, please confirm <br/>
          your details so that we can email you a copy.
        </div>

        <form [formGroup]='form' class="churchos-ts-main-form">

          <div class="churchos-ts-form-half">

            <!-- First Name -->
            <mat-form-field appearance="outline" >
              <mat-label>First name</mat-label>
              <input matInput placeholder="First name" formControlName="firstName">
              <mat-error *ngIf="!!form.get('firstName').errors?.required">Please enter your first name</mat-error>
            </mat-form-field>

            <!-- last Name -->
            <mat-form-field appearance="outline">
              <mat-label>Last name</mat-label>
              <input matInput placeholder="Last name" formControlName="lastName">
              <mat-error *ngIf="!!form.get('lastName').errors?.required">Please enter your last name</mat-error>
            </mat-form-field>

          </div>

          <div class="churchos-ts-form-full">

            <!-- email -->
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" formControlName="email">
              <mat-error *ngIf="!!form.get('email').errors?.required">Please enter your valid email</mat-error>
              <mat-error *ngIf="!!form.get('email').errors?.email">Invalid Email, Valid format is <strong>name@company.abc</strong></mat-error>
            </mat-form-field>

          </div>

          <div class="churchos-ts-form-half">

            <!-- Church Name -->
            <mat-form-field appearance="outline">
              <mat-label>Church name</mat-label>
              <input matInput placeholder="Church name" formControlName="churchName">
              <mat-error *ngIf="!!form.get('churchName').errors?.required">Please enter your church name</mat-error>
            </mat-form-field>

            <!-- Zip Code -->
            <mat-form-field appearance="outline">
              <mat-label>Zip code</mat-label>
              <input matInput placeholder="Zip code" formControlName="zipCode" (keypress)="numberOnly($event)">
              <mat-error *ngIf="!!form.get('zipCode').errors?.required">Please enter your zip code</mat-error>
              <mat-error *ngIf="!!form.get('zipCode').errors?.pattern">Please enter your zip code in a valid format</mat-error>
            </mat-form-field>

          </div>

        </form>

        <div class="unlock-your-report">
          <button mat-button class="forty_parables_cta_btn ic-button ic-redbtn get-started" (click)="form.invalid ? showErrors() : submitForm()">UNLOCK YOUR REPORT</button>
        </div>

      </div>

      <!-- edit response btn -->
      <div class="edit-your-response" *ngIf="displayForm">
        <a (click)="hideForm()">
          <span class="material-icons">keyboard_arrow_left</span>
          Edit your responses
        </a>
      </div>

    </div>

  </div><!-- churchos-third-screen -->

</div><!-- churchos-page -->
